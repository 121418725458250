import React, { useContext, useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';

import { useAuth0 } from '@auth0/auth0-react';
import { useAPI } from 'src/Hooks/useAPI/useAPI';
import { Environments } from 'src/Environments/Environments';
import { ErrorScreen } from 'src/Screens/ErrorScreen/ErrorScreen';
import { LoadingScreen } from 'src/Screens/LoadingScreen/LoadingScreen';
import { LanguageContext } from 'src/Components/languageContext/LanguageContext';
import { GlobalProvider } from 'src/Contexts/GlobalContext/GlobalContext';

const tenantsToRedirect = [ 'AvoJourney', 'ManarFruit' ];

const userType = [ 'productor', 'exportador' ];

const PrivateRoute = () => {

  const { t } = useContext(LanguageContext);
  const { isLoading, error, isAuthenticated, loginWithRedirect, user, getAccessTokenSilently } = useAuth0();

  const tenant = user?.['https://claims.ibisa.co/tenant'][0];
  const [ data, setData ] = useState(false);
  const userData = useAPI({
    url: `${Environments.toolbox}users/${user?.sub}`,
    dependencies: [ isLoading ],
    condition: !isLoading && isAuthenticated
  });

  const getUserData = async () => {
    try {
      const token = await getAccessTokenSilently();
      const newData = {
        numDoc: userData.response.data?.function.find(f => f.substring(0, 3) === 'ND-')?.replace('ND-', '') ?? '0',
        name: userData.response.data?.name,
        email: userData.response.data?.email,
        nickname: userData.response.data?.nickname,
        functions: userData.response.data?.function.filter(f => !userType.includes(f.toLowerCase()) && f !== 'trazabilidad'),
        tenant: userData.response.data?.tenant,
        group: userData.response.data?.function.find(f => userType.includes(f.toLowerCase())) ?? userData.response.data?.group,
        token: token
      }
      setData(newData);
      console.log(newData);
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => { if (!isLoading && isAuthenticated && !userData.loading && !userData.error) getUserData() }, [ isLoading, userData.loading ]);

  
  
  if (isLoading) return <LoadingScreen message={t('loading.cargandoibisa')} />;
  
  if (!isLoading && !isAuthenticated) {
    loginWithRedirect(Environments.ibisa20);
    return <ErrorScreen message={"No estás logueado, redirigiendo..."} />;
  }
  
  if (!isLoading && error) return <ErrorScreen componentsToReload={[ () => window.location.reload() ]} />
  
  if (
    tenantsToRedirect.includes(tenant) &&
    userData.loading
  ) return <LoadingScreen message={t('loading.cargandoibisa')} />
  else if (
    tenantsToRedirect.includes(tenant) &&
    !userData.loading && !userData.error && data &&
    userData?.response?.data?.function.includes("trazabilidad")
  ) {
    window.location.replace(Environments.trazabilidad + tenant + "?data=" + btoa(JSON.stringify(data)));
    return <LoadingScreen message={t('loading.cargandoibisa')} />
  } else if (
    tenantsToRedirect.includes(tenant) &&
    !userData.loading && userData.error
  ) return <ErrorScreen componentsToReload={[ () => window.location.reload() ]} />;
  else if (tenantsToRedirect.includes(tenant) && (
    userData?.response?.data?.function ? userData?.response.data.function.includes("trazabilidad") : true
  )) {return <LoadingScreen message={t('loading.cargandoibisa')} />}
  
  if (isAuthenticated) return <GlobalProvider>
    <Outlet />
  </GlobalProvider>;
  else {
    loginWithRedirect();
    return <></>;
  }
};

export default PrivateRoute;